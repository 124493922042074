import React from "react"

import {
    Button,
    Grid,
    H2,
    Picture,
    Section,
    Stage,
    Spinner,
    Theme
} from "@lsg/components";
import {connect} from "react-redux";
import {Page} from "../Page/Page";

class TalentBankPage extends Page {

    constructor(props) {
        super(props);
        const query = `query ReadOneTalentBankPage($Locale: Locale, $Filter: TalentBankPageFilterFields){
  readOneTalentBankPage(locale: $Locale, filter: $Filter) {
      title,
      content,
      heroImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
        },
        discoverTitle,
        internTitle,
        internText,
        internText2,
        internOffersTitle,
        internOffersText,
        internOffersButtonText,
        internOffersButtonLink,
        discoverImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
        },
        internOffersImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
        },
      
}
}`;

        this.state = {
            ...this.state,
            pageData: {
                heroImage: {
                    absoluteLink: 'header_home-min.png'
                }
            },
            query,
            filters: null
        };
    }

    render() {
        const isLoaded = this.state.loaded;
        if (isLoaded) {
            return (
                <>
                    <Stage
                        theme={Stage.Themes.DARK}
                        // overline="Kostenloses Girokonto"
                        headline={this.state.pageData.title}
                        scrollerText={this.state.scrollerText[this.props.lang.lang.label]}
                        backgroundImageImgSrc={this.state.pageData.heroImage.absoluteLink}
                    />
                    <Section id="section-1">
                        <Grid.Row>
                            <Grid.Column size={6}>
                                <H2>{this.state.pageData.discoverTitle}</H2>
                                {this.formatText(this.state.pageData.content)}
                            </Grid.Column>
                            <Grid.Column size={6}>
                                <Picture imgSrc={this.state.pageData.discoverImage.absoluteLink}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Section>
                    <Theme look={Theme.Dark}>
                        <Section id="section-2">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column size={12}>
                                        <H2>{this.state.pageData.discoverImage.internTitle}</H2>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column size={6}>
                                        {this.formatText(this.state.pageData.internText)}
                                    </Grid.Column>
                                    <Grid.Column size={6}>
                                        {this.formatText(this.state.pageData.internText2)}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Section>
                    </Theme>
                    <Section id="section-3">
                        <Grid.Row>
                            <Grid.Column size={6}/>
                            <Grid.Column size={6}>
                                <H2>{this.state.pageData.internOffersTitle}</H2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column size={6}>
                                <Picture imgSrc={this.state.pageData.internOffersImage.absoluteLink}/>
                            </Grid.Column>
                            <Grid.Column size={6}>
                                {this.formatText(this.state.pageData.internOffersText)}
                                <Button href="https://commerzbank-poland.breezy.hr/" htmlAttrs={{target: "_blank"}}>Zobacz
                                    oferty staży</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Section>
                </>
            );
        }
        return (
            <Section className="page-loader">
                <Grid>
                    <Grid.Row>
                        <Grid.Column size={12} horizontalAlign={Grid.HorizontalAlign.CENTER}>
                            <Spinner/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Section>
        );

    }
}

// export default NewsPage
function mapStateToProps(state) {
    return {lang: state.lang}
}

export default connect(mapStateToProps)(TalentBankPage)
