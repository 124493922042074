import React from "react"
import {connect} from "react-redux";
import TurndownService from 'turndown'
import ReactMarkdown from 'react-markdown/'
import {Grid, H1, H2, H3, H4, H5, H6, List, Paragraph, Section, Spinner} from "@lsg/components";
import {compose} from "@reduxjs/toolkit";
import {withRouter} from "react-router-dom";

/*
 * Component which serves the purpose of a "root route component".
 */
export class Page extends React.Component {
    constructor(props) {
        super(props);
        let url = null;
        if (this.props.location !== undefined) {
            const pathName = this.props.location.pathname;
            if (pathName) {
                const pathArray = pathName.split("/");
                url = pathArray[pathArray.length - 1];
            }
        }

        const query = `query ReadOnePage($Locale: Locale, $Filter: PageFilterFields){
  readOnePage(locale: $Locale, filter: $Filter) {
    ...on PageInterface {
      id,
      menuTitle,
      title,
      urlSegment,
      link,
      absoluteLink,
      view,
      content
    }
  }
}`;

        this.state = {
            scrollerText: {
                pl: 'Czytaj dalej',
                en: 'Read more'
            },
            pageData: [],
            query,
            loaded: false,
            locale: "pl_PL",
            filters: {
                "urlSegment": {
                    "eq": url
                }
            }
        };
    }

    componentDidMount() {
        this.fetchData(this.state.query, this.props.lang.lang.value, this.state.filters)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lang.lang.value !== this.props.lang.lang.value) {
            this.fetchData(this.state.query, this.props.lang.lang.value, this.state.filters)
        }
    }

    formatText = (text) => {
        const turndown = new TurndownService({linkStyle: 'referenced'})

        return (
            <ReactMarkdown
                children={turndown.turndown(text)}
                components={
                    {
                        h1: H1,
                        h2: H2,
                        h3: H3,
                        h4: H4,
                        h5: H5,
                        h6: H6,
                        li: List.Item,
                        /* eslint-disable react/jsx-props-no-spreading */
                        ol: ({node, ...props}) => <List isOrdered {...props} />,
                        /* eslint-disable react/jsx-props-no-spreading */
                        ul: ({node, ...props}) => <List  {...props} />,
                        /* eslint-disable react/jsx-props-no-spreading */
                        p: ({node, ...props}) => <Paragraph {...props} />,
                        /* eslint-disable react/jsx-props-no-spreading */
                        em: ({node, ...props}) => <i style={{color: 'red'}} {...props} />
                    }

                }
            />
        )
    }

    fetchData = (query, locale, filters) => {
        fetch(process.env.REACT_APP_APIURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                query,
                variables: {
                    "Locale": locale,
                    "Filter": filters
                },
            })
        })
            .then(r => r.json())
            .then((data) => {   
                this.setState({...this.state, pageData: data.data[Object.keys(data.data)[0]]});
                this.setState({...this.state, loaded: true})

                document.title = this.state.pageData.title + ' | Commerzbank - Branch in Poland';
                const {hash} = window.location;
                if (hash) {
                    setTimeout(() => {
                        const section = document.getElementById(hash.substring(1));
                        if (section) {
                            section.scrollIntoView({behavior: 'smooth', block: 'center'});
                        }
                    }, 500);

                }
                window.dispatchEvent(new Event('resize'));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    render() {
        const isLoaded = this.state.loaded;
        if (isLoaded) {
            return (
                <>
                    <Section className="news-content page-content">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column horizontalAlign="center" horizontalAlignFeaturePreview>
                                    <H1> {this.state.pageData.title}</H1>
                                    {this.formatText(this.state.pageData.content)}
                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </Section>
                </>
            );
        }
        return (
            <Section className="page-loader">
                <Grid>
                    <Grid.Row>
                        <Grid.Column size={12} horizontalAlign={Grid.HorizontalAlign.CENTER}>
                            <Spinner/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Section>
        );
    }
}

//export default Page

function mapStateToProps(state) {
    return {lang: state.lang}
}

//export default connect(mapStateToProps)(Page)

export default compose(
    withRouter,
    connect(mapStateToProps)
)(Page)
