import React from "react"
import {withRouter} from "react-router-dom";
import TurndownService from 'turndown'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import {
    ActionGroup,
    Button, GallerySlider,
    Grid,
    H1, H2, H4, H5, H6,
    IconLink, IconLinkGroup, Link,
    List,
    Paragraph,
    Picture,
    Section,
    Stage,
    Spinner,
    Theme, withReactRouterLink, H3, Video
} from "@lsg/components";
import { compose } from '@reduxjs/toolkit'
import {connect} from "react-redux";
import { Page } from "../Page/Page";
import Hero from "../../components/Hero";


const RouterIconLink = withReactRouterLink(IconLink);
const RouterButton = withReactRouterLink(Button);
const turndown = new TurndownService({linkStyle: 'referenced'})
turndown.keep(['iframe','div'])

class NewsPage extends Page {

    constructor(props) {
        super(props);
        const query = `query ReadOneNews($Locale: Locale, $Filter: NewsFilterFields){
  readOneNews(locale: $Locale, filter: $Filter) {
    ...on News {
      title,
      content,
      lead,
      newsListPageLink,
      coverImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
      sortedImages {
        nodes {
            ...on Image{
              fileFilename,
              absoluteLink
            }
        }
      }
  }
}
}`;

        //const location = useLocation();
        // console.log(this.props.location.state.newsId)

        this.state = {
            photoIndex: 0,
            isOpen: false,
            backLabel: {
              pl: 'Wróć',
              en: 'Go Back'
            },
            pageData: {
                heroImage: {
                    absoluteLink: 'header_home-min.png'
                }
            },
            query,
            filters: {
                "id": {
                    "eq": this.props.location.state.newsId
                }
            },
            locale: 'pl_PL'
        };
    }

    render() {
        const isLoaded = this.state.loaded;
        const { photoIndex, isOpen } = this.state;

        if (isLoaded) {
            const hasLead = this.state.pageData.lead;
            const hasGallery = this.state.pageData.sortedImages.nodes.length;
            const images = [];

            if(hasGallery){
                {this.state.pageData.sortedImages.nodes.map((sortedImage, i) => (
                    images.push(sortedImage.absoluteLink)
                ))}
            }

            return (
                <>
                    <Section className="news-content">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column horizontalAlign="center" horizontalAlignFeaturePreview className="typography">
                                    <H1> {this.state.pageData.title}</H1>
                                    {hasLead ? (
                                        <Paragraph size="lead-text">
                                            <strong>
                                                { this.state.pageData.lead }
                                            </strong>
                                        </Paragraph>) : ''}
                                    <ReactMarkdown
                                        rehypePlugins={[rehypeRaw]}
                                        children={turndown.turndown(this.state.pageData.content)}
                                        components={
                                            {
                                                h1: H1,
                                                h2: H2,
                                                h3: H3,
                                                h4: H4,
                                                h5: H5,
                                                h6: H6,
                                                li: List.Item,
                                                img: ({node, ...props}) => <Picture imgSrc={props.src} alt={props.alt}/>,
                                                /* eslint-disable react/jsx-props-no-spreading */
                                                ol: ({node, ...props}) => <List centeredLayout isOrdered {...props} />,
                                                /* eslint-disable react/jsx-props-no-spreading */
                                                ul: ({node, ...props}) => <List centeredLayout {...props} />,
                                                /* eslint-disable react/jsx-props-no-spreading */
                                                p: ({node, ...props}) => <Paragraph centeredLayout {...props} />,
                                                /* eslint-disable react/jsx-props-no-spreading */
                                                em: ({node, ...props}) => <i style={{color: 'red'}} {...props} />,
                                                /* eslint-disable react/jsx-props-no-spreading */
                                                // eslint-disable-next-line jsx-a11y/iframe-has-title
                                                //iframe: ({node, ...props}) => <div>Test</div>
                                            }

                                        }
                                        />
                                    {/* <div dangerouslySetInnerHTML={{__html: this.state.pageData.content}}/> */}

                                    {/*<Video
                                        videoTitle="Join It In Lodz"
                                        src="/join_it_in_lodz.mp4"
                                        textReplayButton="odtwórz ponownie"
                                    />*/}
                                </Grid.Column>
                            </Grid.Row>
                            {hasGallery ? (
                            <Grid.Row>
                                <Grid.Column>
                                    <GallerySlider>
                                        {this.state.pageData.sortedImages.nodes.map((sortedImage, i) => (
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                                                <span key={`${i  }-btn`} role="button" className="gal-btn" onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                                                    <GallerySlider.Item key={`${i  }-img`} pictureImgSrc={sortedImage.absoluteLink}   />
                                                </span>
                                        ))}
                                    </GallerySlider>
                                </Grid.Column>
                            </Grid.Row>
                                ) : '' }
                            <Grid.Row className="back-bt-row">
                                <Grid.Column>
                                    <RouterButton to={this.state.pageData.newsListPageLink}>{this.state.backLabel[[this.props.lang.lang.label]]}</RouterButton>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {isOpen && (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + images.length - 1) % images.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % images.length,
                                    })
                                }
                            />
                        )}
                    </Section>
                </>
            );
        }
        return (
            <Section className="page-loader">
                <Grid>
                    <Grid.Row>
                        <Grid.Column size={12} horizontalAlign={Grid.HorizontalAlign.CENTER}>
                            <Spinner/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Section>
        );

    }
}

// export default NewsPage
function mapStateToProps(state) {
    return { lang: state.lang }
}

export default compose(
    withRouter,
    connect(mapStateToProps)
)(NewsPage)
