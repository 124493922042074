import {
    ActionButton,
    ContactModule,
    SimpleFooter, withReactRouterNavigation
} from "@lsg/components";
import {communication___call, location___location} from "@lsg/icons";
import * as React from "react";
import {compose} from "@reduxjs/toolkit";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const RouterFooter = withReactRouterNavigation(SimpleFooter);

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subline: {
                pl: "Wersalska 6, 91-203 Łódź",
                en: "Wersalska 6, 91-203 Lodz"
            },
            iconLabels: {
                location : {
                    pl: "adres",
                    en: "address"
                },
                phone : {
                    pl: "telefon",
                    en: "phone"
                }
            },
            footerTree: {
                pl: [
                    {
                        label: "Polityka cookies",
                        to: "/pl/polityka-prywatnosci",
                        name: "item-1"
                    }
                ],
                en: [
                    {
                        label: "Cookies Policy",
                        to: "/en/cookies-policy",
                        name: "item-1"
                    }
                ]
            },
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        /*if (prevProps.lang.lang.value !== this.props.lang.lang.value) {
        }*/
    }

    render() {
        return (
            <>
                <RouterFooter
                    contactModule={
                        <div className="contact-wrapper">
                            <ContactModule className="contact-header" headline="Commerzbank AG" subline={this.state.subline[this.props.lang.lang.label]}/>
                            <div className="contact-items">
                                <ContactModule headline="" subline="">
                                    <ActionButton href="https://www.google.com/maps/place/Wersalska+6,+91-203+%C5%81%C3%B3d%C5%BA/@51.8028635,19.3914454,17z/data=!3m1!4b1!4m5!3m4!1s0x471bb5694a0a3ea5:0xc0db7737431ea779!8m2!3d51.8028635!4d19.3936341" htmlAttrs={{ target: "_blank" }} icon={location___location} look={ActionButton.Looks.SECONDARY}>
                                        {this.state.iconLabels.location[[this.props.lang.lang.label]]}
                                    </ActionButton>
                                    <ActionButton href="tel:601601601" htmlAttrs={{ target: "_blank" }} icon={communication___call} look={ActionButton.Looks.SECONDARY}>
                                        {this.state.iconLabels.phone[[this.props.lang.lang.label]]}
                                    </ActionButton>
                                </ContactModule>
                                {/* <ContactModule headline="" subline="Ogrodowa 8, 91-062 Łódź" className="second-line">
                            <ActionButton icon={location___location} look={ActionButton.Looks.SECONDARY} />
                            <ActionButton icon={communication___call} look={ActionButton.Looks.SECONDARY} />
                        </ContactModule> */}
                            </div>
                        </div>

                    }
                    navigationTree={this.state.footerTree[this.props.lang.lang.label]}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return { lang: state.lang }
}

//export default connect(mapStateToProps, mapDispatchToProps)(Header)

export default compose(
    withRouter,
    connect(mapStateToProps)
)(Footer)
