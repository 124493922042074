import React from "react"
import {
    ActionGroup,
    Button, GallerySlider,
    Grid,
    H2, H4,
    Picture,
    Section,
    Stage,
    Spinner,
    Theme, withReactRouterLink
} from "@lsg/components";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
// eslint-disable-next-line import/no-cycle,import/no-named-as-default
import {Page} from "../Page/Page";

const RouterButton = withReactRouterLink(Button);

class HomePage extends Page {

    constructor(props) {
        super(props);

        const query = `query ReadOneHomePage($Locale: Locale, $Filter: HomePageFilterFields){
  readOneHomePage(locale: $Locale, filter: $Filter) {
    ...on HomePageInterface {
      title,
      content,
      heroImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
        headerTextLine1,
        headerTextLine2,
        headerButtonText1,
        headerButtonText2,
        headerButtonLink2 {
            ...on SiteTreeInterface {
              link
            }
        },
        careerTitle,
        careerText,
        careerImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
        careerButtonText,
        careerButtonText2,
        careerButtonLink {
           link
        },
        newsTitle,
        talentBankText,
        bigDataText,
        contactText,
        contactLegalText,
        aboutTitle,
        aboutText,
        aboutButtonText,
        aboutButtonLink {
            link
        },
        aboutImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
      talentBankTitle,
      talentBankImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
        talentBankText,
        talentBankButtonText,
        talentBankButtonLink2,
        talentBankButtonText2,
        talentBankButtonLink {
            link
        },
        bigDataTitle,
        bigDataText,
        bigDataButtonText,
        bigDataButtonLink2,
        bigDataButtonText2,
        bigDataButtonLink {
            link
        },
        bigDataImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
        contactText,
      allNews {
        nodes {
          id,
          title,
          content,
          coverImage {
            ...on Image {
              fileFilename,
              absoluteLink
            }
          },
          link
        }
      }
  }
}
}`;

        this.state = {
            ...this.state,
            pageData: {
                heroImage: {
                    absoluteLink: 'header_home-min.png'
                }
            },
            query,
            filters: null
        };
    }

    render() {
        const isLoaded = this.state.loaded;
        if (isLoaded) {
            return (
                <>
                    <div className="home-stage">
                        <Stage
                            theme={Stage.Themes.DARK}
                            className="home-stage"
                            headline={this.state.pageData.headerTextLine1}
                            leadText={this.state.pageData.headerTextLine2}
                            button={<ActionGroup centeredLayout>
                                <Button href="https://commerzbank-poland.breezy.hr/"
                                        htmlAttrs={{target: "_blank"}}>{this.state.pageData.headerButtonText1}</Button>
                                <RouterButton to={this.state.pageData.careerButtonLink.link}
                                              look={Button.Looks.SECONDARY}>{this.state.pageData.headerButtonText2}</RouterButton>
                            </ActionGroup>}
                            scrollerText={this.state.scrollerText[this.props.lang.lang.label]}
                            backgroundImageImgSrc={this.state.pageData.heroImage.absoluteLink}
                        />
                    </div>
                    <Section className="about-section section">
                        <Grid>
                            <Grid.Row className="p-full-width">
                                <Grid.Column size={12}>
                                    <H2>{this.state.pageData.aboutTitle}</H2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column size={6}>
                                    {this.formatText(this.state.pageData.content)}
                                    <RouterButton to={this.state.pageData.aboutButtonLink.link}
                                                  look={Button.Looks.SECONDARY}>{this.state.pageData.aboutButtonText}</RouterButton>

                                </Grid.Column>
                                <Grid.Column size={6} className="image-col">
                                    <Picture imgSrc={this.state.pageData.aboutImage.absoluteLink}
                                             alt="Dummy alternative text for the image"/>
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                    </Section>
                    <Theme look={Theme.Looks.DARK} className="elevated-section-theme section">
                        <Section className="elevated-section section">
                            <Grid>
                                <Grid.Row className="p-full-width">
                                    <Grid.Column size={6}/>
                                    <Grid.Column size={6}>
                                        <H2>{this.state.pageData.careerTitle}</H2>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="elevated-row">
                                    <Grid.Column size={5}>
                                        <Picture imgSrc={this.state.pageData.careerImage.absoluteLink}
                                                 className="elevated-image"
                                                 alt="Dummy alternative text for the image"/>
                                    </Grid.Column>
                                    <Grid.Column size={6}>
                                        {this.formatText(this.state.pageData.careerText)}

                                        <ActionGroup centeredLayout={false}>
                                            <Button href="https://commerzbank-poland.breezy.hr/"
                                                    htmlAttrs={{target: "_blank"}}>{this.state.pageData.careerButtonText}</Button>
                                            <RouterButton to={this.state.pageData.careerButtonLink.link}
                                                          look={Button.Looks.SECONDARY}>{this.state.pageData.careerButtonText2}</RouterButton>
                                        </ActionGroup>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Section>
                    </Theme>
                    <Section id="wydarzenia" className="news-section section" onClick={this.handleNewsClick}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <H2>{this.state.pageData.newsTitle}</H2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <GallerySlider>
                                        {this.state.pageData.allNews.nodes.map((news, i) => (
                                            <Link className="slider-link" to={{
                                                pathname: news.link,
                                                state: {
                                                    newsId: news.id
                                                }
                                            }} key={news.id}>
                                                <GallerySlider.Item key={1} pictureImgSrc={news.croppedCoverImage}
                                                                    onClick={this.handleNewsClick}>
                                                    <H4 centeredLayout>{news.title}</H4>
                                                </GallerySlider.Item>
                                            </Link>
                                        ))}
                                    </GallerySlider>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                    <Theme look={Theme.Looks.DARK}>
                        <Section>
                            <Grid centeredLayout>
                                <Grid.Row>
                                    <Grid.Column size={5}/>
                                    <Grid.Column size={6}>
                                        <H2>{this.state.pageData.talentBankTitle}</H2>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column size={5}>
                                        <Picture imgSrc={this.state.pageData.talentBankImage.absoluteLink}
                                                 alt="Dummy alternative text for the image"/>
                                    </Grid.Column>
                                    <Grid.Column size={6}>

                                        {this.formatText(this.state.pageData.talentBankText)}
                                        <ActionGroup centeredLayout={false}>
                                            <Button href={this.state.pageData.talentBankButtonLink2}
                                                    htmlAttrs={{target: "_blank"}}>{this.state.pageData.talentBankButtonText}</Button>
                                            <RouterButton to={this.state.pageData.talentBankButtonLink.link}
                                                          look={Button.Looks.SECONDARY}>{this.state.pageData.talentBankButtonText2}</RouterButton>
                                        </ActionGroup>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Section>
                    </Theme>
                    <Section className="section">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column size={6}>
                                    <H2>{this.state.pageData.bigDataTitle}</H2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column size={6}>
                                    {this.formatText(this.state.pageData.bigDataText)}
                                    <ActionGroup centeredLayout={false}>
                                        <RouterButton to={this.state.pageData.bigDataButtonLink.link}
                                                      look={Button.Looks.SECONDARY}>{this.state.pageData.bigDataButtonText}</RouterButton>
                                        <Button look={Button.Looks.SECONDARY}
                                                href={this.state.pageData.bigDataButtonLink2}
                                                htmlAttrs={{target: "_blank"}}>{this.state.pageData.bigDataButtonText2}</Button>
                                    </ActionGroup>
                                </Grid.Column>
                                <Grid.Column size={6}>
                                    <Picture imgSrc={this.state.pageData.bigDataImage.absoluteLink}
                                             alt="Dummy alternative text for the image"/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                    <Section id="kontakt" className="contact-section section">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <H2>{this.state.pageData.contactTitle}</H2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <div className="contact-box-wrapper">
                                        <Picture imgSrc="103184738_148436000091775_5881747358095061027_n@2x-min.png"
                                                 alt="kontakt"/>
                                        <div className="contact-box">
                                            {this.formatText(this.state.pageData.contactText)}
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                    <Section className="legal-notice">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column size={12}>
                                    {this.formatText(this.state.pageData.contactLegalText)}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                </>
            );
        }
        return (
            <Section className="page-loader">
                <Grid>
                    <Grid.Row>
                        <Grid.Column size={12} horizontalAlign={Grid.HorizontalAlign.CENTER}>
                            <Spinner/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Section>
        );
    }
}

// export default HomePage
function mapStateToProps(state) {
    return {lang: state.lang}
}

export default connect(mapStateToProps)(HomePage)
