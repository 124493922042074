import React from "react"

import {
    Grid,
    H2,
    Paragraph,
    Picture,
    Section,
    Stage,
    Spinner,
    Theme,
    H3,
    Video
} from "@lsg/components";
import {connect} from "react-redux";
import {Page} from "../Page/Page";

class AboutUsPage extends Page {

    constructor(props) {
        super(props);
        const query = `query ReadOneAboutUsPage($Locale: Locale, $Filter: AboutUsPageFilterFields){
  readOneAboutUsPage(locale: $Locale, filter: $Filter) {
      title,
      content,
      heroImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
        aboutTitle,
        videoTitle,
        videoText,
        teamsTitle,
        teamsText,
        teamsTitle2,
        teamsTitle3,
        virtualTourTitle,
        virtualTourText,
        worldTitle,
        worldText,
    \t\tviedo {
          fileFilename,
          absoluteLink
        },
        teamsImage {
          fileFilename,
          absoluteLink
        },
        worldImage {
          fileFilename,
          absoluteLink
        },
    \t\tteams(sort: {sortOrder: ASC}){
          ...on TeamConnection {
            nodes {
              title,
              content,
              icon {
                fileFilename,
                absoluteLink,
              }
            }
          }
        }
}
}`;

        this.state = {
            ...this.state,
            pageData: {
                heroImage: {
                    absoluteLink: 'header_home-min.png'
                }
            },
            query,
            filters: null
        };
    }

    render() {
        const isLoaded = this.state.loaded;
        if (isLoaded) {
            return (
                <>
                    <Stage
                        theme={Stage.Themes.DARK}
                        // overline="Kostenloses Girokonto"
                        headline={this.state.pageData.title}
                        scrollerText={this.state.scrollerText[this.props.lang.lang.label]}
                        backgroundImageImgSrc={this.state.pageData.heroImage.absoluteLink}
                    />
                    <Section id="section-1" >
                        <Grid>
                            {/*<Grid.Row>
                                <Grid.Column size={6}>
                                    <H2>{this.state.pageData.aboutTitle}</H2>
                                </Grid.Column>
                            </Grid.Row>*/}
                            <Grid.Row verticalAlign="middle">
                                <Grid.Column size={6}>
                                    <H2>{this.state.pageData.aboutTitle}</H2>
                                    {this.formatText(this.state.pageData.content)}
                                </Grid.Column>
                                <Grid.Column size={6} className="video-column">
                                    <Video
                                        videoTitle={this.state.pageData.videoTitle}
                                        src="/coba_space_480.mp4"
                                        textReplayButton="replay"
                                        left={
                                            <>
                                               {/* <IconLink icon={interaction_arrows_arrowLeft}
                                                          look={IconLink.Looks.LEFT}>
                                                    Zurück
                                                </IconLink>*/}
                                            </>
                                        }
                                        right={
                                            <>
                                                {/*<IconLink icon={communication_feedback_like}
                                                          look={IconLink.Looks.RIGHT}>
                                                    Feedback
                                                </IconLink>
                                                <IconLink icon={interaction___share} look={IconLink.Looks.RIGHT}>
                                                    Share
                                                </IconLink>*/}
                                            </>
                                        }
                                    />
                                    {this.formatText(this.state.pageData.videoText)}
                                    {/*<Paragraph><strong>ul. Ogrodowa 8 – jedno z biur Commerzbanku w
                                        Łodzi</strong></Paragraph>*/}
{/*
                                    <Paragraph><strong>Nasze biura w Łodzi znajdują się w dwóch lokalizacjach: ul. Wersalska 6 (główna siedziba) i ul. Ogrodowa 8</strong></Paragraph>
*/}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                    <Theme look={Theme.Looks.DARK}>
                        <Section id="section-2">
                            <Grid>
                                {/*<Grid.Row>
                                    <Grid.Column size={6}>
                                        <H2>{this.state.pageData.teamsTitle}</H2>
                                        <H3>{this.state.pageData.teamsTitle3}</H3>

                                    </Grid.Column>
                                </Grid.Row>*/}
                                <Grid.Row verticalAlign="middle">
                                    <Grid.Column size={6}>
                                        <H2>{this.state.pageData.teamsTitle}</H2>
                                        <H3>{this.state.pageData.teamsTitle3}</H3>
                                        {this.formatText(this.state.pageData.teamsText)}
                                    </Grid.Column>
                                    <Grid.Column size={6}>
                                        <Picture imgSrc={this.state.pageData.teamsImage.absoluteLink} alt="Dummy alternative text for the image"/>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column size={12}>
                                        <H3 className="heading-margin">{this.state.pageData.teamsTitle2}</H3>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="teams-list">
                                    {this.state.pageData.teams.nodes.map((team, i) => (
                                        <Grid.Column size={6} key={`${i  }-team`}>
                                            <div className="team-itm">
                                                <Picture imgSrc={team.icon.absoluteLink} alt={team.title}/>
                                                <h4 dangerouslySetInnerHTML={{__html: team.title}} className="lsgs-MS4xNC4w--h4 "></h4>
                                                <Paragraph>
                                                    {team.content}
                                                </Paragraph>
                                            </div>
                                        </Grid.Column>
                                    ))}
                                </Grid.Row>
                            </Grid>
                        </Section>
                    </Theme>
                    <Section id="section-3">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column size={12}>
                                    <H2>{this.state.pageData.virtualTourTitle}</H2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column size={12}>
                                    {/*<Picture imgSrc="/spacer.png" alt="Dummy alternative text for the image"/>*/}
                                    <iframe title="virtual-tour" className="virtual-tour-iframe" width='100%'  src='https://roundme.com/embed/263891/1192138'
    frameBorder='0' webkitallowfullscreen="true" mozallowfullscreen="true"
    allowFullScreen/>
                                    <Paragraph><strong>{this.state.pageData.virtualTourText}</strong></Paragraph>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                    <Section id="section-4">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column size={6}>
                                    <H2>{this.state.pageData.worldTitle}</H2>
                                    {this.formatText(this.state.pageData.worldText)}
                                </Grid.Column>
                                <Grid.Column size={6}>
                                    <Picture imgSrc={this.state.pageData.worldImage.absoluteLink}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                </>
            );
        }
        return (
            <Section className="page-loader">
                <Grid>
                    <Grid.Row>
                        <Grid.Column size={12} horizontalAlign={Grid.HorizontalAlign.CENTER}>
                            <Spinner/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Section>
        );

    }
}

// export default NewsPage
function mapStateToProps(state) {
    return {lang: state.lang}
}

export default connect(mapStateToProps)(AboutUsPage)
