import React from "react"

import {
    Button,
    Grid,
    H2,
    Paragraph,
    Picture,
    Section,
    Stage,
    Spinner,
    Theme
} from "@lsg/components";
import {connect} from "react-redux";
import {Page} from "../Page/Page";

class BigDataPage extends Page {

    constructor(props) {
        super(props);
        const query = `query ReadOneBigDataPage($Locale: Locale, $Filter: BigDataPageFilterFields){
  readOneBigDataPage(locale: $Locale, filter: $Filter) {
      title,
      content,
      heroImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
       bigDataTitle,
       meetupTitle,
       meetupText,
       meetupButtonText,
       meetupButtonLink,
       bigDataBankTitle,
       bigDataBankText,
       bigDataBankButtonText,
       bigDataBankButtonLink,
       bigDataImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
      bigDataBank {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
      meetupImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
}
}`;

        this.state = {
            ...this.state,
            pageData: {
                heroImage: {
                    absoluteLink: 'header_home-min.png'
                }
            },
            query,
            filters: null
        };
    }

    render() {
        const isLoaded = this.state.loaded;
        if (isLoaded) {
            return (
                <>
                    <Stage
                        theme={Stage.Themes.DARK}
                        headline={this.state.pageData.title}
                        scrollerText={this.state.scrollerText[this.props.lang.lang.label]}
                        backgroundImageImgSrc={this.state.pageData.heroImage.absoluteLink}
                    />
                    <Section>
                        <Grid.Row>
                            <Grid.Column size={6}>
                                <H2>{this.state.pageData.bigDataTitle}</H2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column size={6}>
                                <Paragraph>
                                    {this.formatText(this.state.pageData.content)}
                                </Paragraph>
                            </Grid.Column>
                            <Grid.Column size={6}>
                                <Picture imgSrc={this.state.pageData.bigDataImage.absoluteLink}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Section>
                    <Theme look={Theme.Dark} className="elevated-section-theme">
                        <Section className="elevated-section" id="section-1">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column size={6} />
                                    <Grid.Column size={6}>
                                        <H2>{this.state.pageData.meetupTitle}</H2>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column size={6}>
                                        <Picture className="elevated-image" imgSrc={this.state.pageData.meetupImage.absoluteLink}/>
                                    </Grid.Column>
                                    <Grid.Column size={6}>
                                        {this.formatText(this.state.pageData.meetupText)}
                                        <Button look={Button.Looks.SECONDARY} href={this.state.pageData.meetupButtonLink} htmlAttrs={{ target: "_blank" }}>{this.state.pageData.meetupButtonText}</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Section>
                    </Theme>
                    <Section id="section-2">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column size={6}>
                                    <H2>{this.state.pageData.bigDataBankTitle}</H2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column size={6}>
                                    {this.formatText(this.state.pageData.bigDataBankText)}
                                    <Button href={this.state.pageData.bigDataBankButtonLink} look={Button.Looks.SECONDARY} htmlAttrs={{ target: "_blank" }}> {this.state.pageData.bigDataBankButtonText}</Button>

                                </Grid.Column>
                                <Grid.Column size={6}>
                                    <Picture className="elevated-image" imgSrc={this.state.pageData.bigDataBank.absoluteLink}/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                </>
            );
        }
        return (
            <Section className="page-loader">
                <Grid>
                    <Grid.Row>
                        <Grid.Column size={12} horizontalAlign={Grid.HorizontalAlign.CENTER}>
                            <Spinner/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Section>
        );

    }
}

// export default NewsPage
function mapStateToProps(state) {
    return {lang: state.lang}
}

export default connect(mapStateToProps)(BigDataPage)
