import React from "react"
import {
    Grid,
    H2,
    Picture,
    Section,
    Spinner
} from "@lsg/components";
import {connect} from "react-redux";
// eslint-disable-next-line import/no-cycle,import/no-named-as-default
import {Page} from "../Page/Page";

class ContactPage extends Page {

    constructor(props) {
        super(props);

        const query = `query ReadOneContactPage($Locale: Locale, $Filter: ContactPageFilterFields){
  readOneContactPage(locale: $Locale, filter: $Filter) {
    ...on ContactPageInterface {
      title,
        contactText,
        contactLegalText
  }
}
}`;

        this.state = {
            ...this.state,
            pageData: {},
            query,
            filters: null
        };
    }

    render() {
        const isLoaded = this.state.loaded;
        if (isLoaded) {
            return (
                <>
                    <Section id="kontakt" className="contact-section">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <H2>{this.state.pageData.contactTitle}</H2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <div className="contact-box-wrapper">
                                        <Picture imgSrc="/103184738_148436000091775_5881747358095061027_n@2x-min.png"
                                                 alt="kontakt"/>
                                        <div className="contact-box">
                                            {this.formatText(this.state.pageData.contactText)}
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                    <Section className="legal-notice">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column size={12}>
                                    {this.formatText(this.state.pageData.contactLegalText)}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                </>
            );
        }
        return (
            <Section className="page-loader">
                <Grid>
                    <Grid.Row>
                        <Grid.Column size={12} horizontalAlign={Grid.HorizontalAlign.CENTER}>
                            <Spinner/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Section>
        );

    }
}

// export default ContactPage
function mapStateToProps(state) {
    return {lang: state.lang}
}

export default connect(mapStateToProps)(ContactPage)
