import React from "react"
import {
    GallerySlider,
    Grid,
    H2, H4,
    Section,
    Spinner,
} from "@lsg/components";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
// eslint-disable-next-line import/no-cycle,import/no-named-as-default
import {Page} from "../Page/Page";

class NewsListPage extends Page {

    constructor(props) {
        super(props);

        const query = `query ReadOneNewsListPage($Locale: Locale, $Filter: NewsListPageFilterFields){
  readOneNewsListPage(locale: $Locale, filter: $Filter) {
    ...on NewsListPageInterface {
      title,
      allNews {
        nodes {
          id,
          title,
          content,
          coverImage {
            ...on Image {
              fileFilename,
              absoluteLink
            }
          },
          link
        }
      }
  }
}
}`;

        this.state = {
            ...this.state,
            pageData: {},
            query,
            filters: null
        };
    }

    render() {
        const isLoaded = this.state.loaded;
        if (isLoaded) {
            return (
                <>
                    <Section id="wydarzenia" className="news-section">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <H2>{this.state.pageData.newsTitle}</H2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <GallerySlider>
                                        {this.state.pageData.allNews.nodes.map((news, i) => (
                                            <Link className="slider-link" to={{
                                                pathname: news.link,
                                                state: {
                                                    newsId: news.id
                                                }
                                            }} key={news.id}>
                                                <GallerySlider.Item key={1} pictureImgSrc={news.coverImage.absoluteLink}
                                                                    onClick={this.handleNewsClick}>
                                                    <H4 centeredLayout>{news.title}</H4>
                                                </GallerySlider.Item>
                                            </Link>
                                        ))}
                                    </GallerySlider>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                </>
            );
        }
        return (
            <Section className="page-loader">
                <Grid>
                    <Grid.Row>
                        <Grid.Column size={12} horizontalAlign={Grid.HorizontalAlign.CENTER}>
                            <Spinner/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Section>
        );

    }
}

// export default NewsListPage
function mapStateToProps(state) {
    return {lang: state.lang}
}

export default connect(mapStateToProps)(NewsListPage)
