import * as React from "react";
import {compose} from "@reduxjs/toolkit";
import {connect} from "react-redux";
import {setContrast} from "../features/contrast/contrastSlice";
import {setLang} from "../features/lang/langSlice";

class Contrast extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawer: false,
            selectedContrast: props.contrast
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        /*if (prevProps.lang.lang.value !== this.props.lang.lang.value) {
        }*/
    }

    handleContrastButton = (e) => {
        e.preventDefault();
        this.setState({drawer: (!this.state.drawer)})
    }

    handleSelectContrast = (value) => {

        if(this.state.selectedContrast !== value){
            const bodyEl = document.getElementsByTagName('body')[0];

            // eslint-disable-next-line no-plusplus
            for (let i=0, l=bodyEl.classList.length; i<l; ++i) {
                if(/contrast-.*/.test(bodyEl.classList[i])) {
                    console.log(bodyEl.classList[i]);
                    bodyEl.classList.remove(bodyEl.classList[i]);
                }
            }

            bodyEl.classList.add(`contrast-${  value}`);

            this.props.setContrast(value);
            this.setState({selectedContrast: value})
        }
    }


    render() {
        const isDrawerOpen = this.state.drawer;
        return (
            <>
                <div className="contrast-wrapper">
                    <span role="button" tabIndex={0} className={`contrast-bt ${isDrawerOpen ? "active" : ""}`} onKeyDown={this.handleContrastButton} onClick={this.handleContrastButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490 490" style={{enableBackground:"new 0 0 490 490" }}><path d="M490 245C490 109.9 380.1 0 245 0S0 109.9 0 245s109.9 245 245 245 245-109.9 245-245zM245 79c91.5 0 166 74.5 166 166s-74.5 166-166 166V79z"/></svg>
                    </span>
                    {isDrawerOpen ? (
                        <div className="contrast-buttons">
                            <div className="contrast-buttons-inner">
                                <span role="button" tabIndex={0} className="reset contrast-select-bt" onClick={() => this.handleSelectContrast('initial')} onKeyDown={() => this.handleSelectContrast('initial')}>X</span>
                                <span role="button" tabIndex={0} className="black-white contrast-select-bt" onClick={() => this.handleSelectContrast('black-white')} onKeyDown={() => this.handleSelectContrast('black-white')}>a</span>
                                <span role="button" tabIndex={0} className="black-yellow contrast-select-bt" onClick={() => this.handleSelectContrast('black-yellow')} onKeyDown={() => this.handleSelectContrast('black-yellow')}>a</span>
                                <span role="button" tabIndex={0} className="yellow-black contrast-select-bt" onClick={() => this.handleSelectContrast('yellow-black')} onKeyDown={() => this.handleSelectContrast('yellow-black')}>a</span>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return { contrast: state.contrast }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setContrast: (action) => dispatch(setContrast(action))
    }
};

//export default connect(mapStateToProps, mapDispatchToProps)(Header)

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Contrast)
