import React from "react"

import {
    Button,
    Grid,
    H2, H4,
    Paragraph,
    Picture,
    Section,
    Stage,
    Spinner,
    Theme
} from "@lsg/components";
import {connect} from "react-redux";
import {Page} from "../Page/Page";

class CareerPage extends Page {

    constructor(props) {
        super(props);
        const query = `query ReadOneCareerPage($Locale: Locale, $Filter: CareerPageFilterFields){
  readOneCareerPage(locale: $Locale, filter: $Filter) {
      title,
      content,
      heroImage {
        ...on Image{
          fileFilename,
          absoluteLink
        }
      },
        careerTitle,
        careerButtonText,
        careerButtonLink,
        searchTitle,
        testimonialsTitle,
        careerImage {
          fileFilename,
          absoluteLink
        },
    \t\ttiles(sort: {sort: ASC}){
          ...on TileConnection {
            nodes {
              title,
              content,
              image {
                fileFilename,
                absoluteLink,
              }
            }
          }
        },
        testimonials(sort: {sort: ASC}){
          ...on TestimonialConnection {
            nodes {
              title,
              content,
              image {
                fileFilename,
                absoluteLink,
              },
              croppedImage
            }
          }
        }
}
}`;

        this.state = {
            ...this.state,
            pageData: {
                heroImage: {
                    absoluteLink: 'header_home-min.png'
                }
            },
            query,
            filters: null
        };
    }

    render() {
        const isLoaded = this.state.loaded;
        if (isLoaded) {
            return (
                <>
                    <div className="career-stage">
                        <Stage
                            theme={Stage.Themes.DARK}
                            headline={this.state.pageData.title}
                            scrollerText={this.state.scrollerText[this.props.lang.lang.label]}
                            backgroundImageImgSrc={this.state.pageData.heroImage.absoluteLink}
                        />
                    </div>
                    <Section id="section-1">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column size={6}>
                                    <H2>{this.state.pageData.careerTitle}</H2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column size={6}>
                                    {this.formatText(this.state.pageData.content)}
                                    <Button href={this.state.pageData.careerButtonLink}
                                            htmlAttrs={{target: "_blank"}}>{this.state.pageData.careerButtonText}</Button>
                                </Grid.Column>
                                <Grid.Column size={6}>
                                    <Picture imgSrc={this.state.pageData.careerImage.absoluteLink}
                                             alt="Dummy alternative text for the image"/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                    <Theme look={Theme.Dark}>
                        <Section id="section-2">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column size={12}>
                                        <H2>{this.state.pageData.searchTitle}</H2>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    {this.state.pageData.tiles.nodes.map((tile, i) => (
                                        <Grid.Column size={4} key={`${i}-itm`}>
                                            <Picture imgSrc={tile.image.absoluteLink}/>
                                            <H4>{tile.title}</H4>
                                            <Paragraph>
                                                {tile.content}
                                            </Paragraph>
                                        </Grid.Column>
                                    ))}
                                </Grid.Row>
                            </Grid>
                        </Section>
                    </Theme>
                    <Section id="section-3">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column size={12}>
                                    <H2>{this.state.pageData.testimonialsTitle}</H2>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>

                                <Grid.Column size={12}>
                                    {this.state.pageData.testimonials.nodes.map((testimonial, i) => (
                                        <div className="testimonial-itm" key={`${i  }-testimonial`}>
                                            <div className="tst-image">
                                                <Picture imgSrc={testimonial.croppedImage}/>
                                            </div>
                                            <div className="tst-text">
                                                <Paragraph>
                                                    {testimonial.content}</Paragraph>
                                                <Paragraph>
                                                    <strong>{testimonial.title}</strong>
                                                </Paragraph>
                                            </div>
                                        </div>
                                    ))}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Section>
                </>
            );
        }
        return (
            <Section className="page-loader">
                <Grid>
                    <Grid.Row>
                        <Grid.Column size={12} horizontalAlign={Grid.HorizontalAlign.CENTER}>
                            <Spinner/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Section>
        );

    }
}

// export default NewsPage
function mapStateToProps(state) {
    return {lang: state.lang}
}

export default connect(mapStateToProps)(CareerPage)
