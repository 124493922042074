/* eslint-disable camelcase */
import * as React from "react";
import Layout from "./Layout";

import './App.scss';

export default () => {

    return (
        <>
           <Layout/>
        </>
    );
};
