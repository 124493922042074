import { configureStore } from '@reduxjs/toolkit'
import langReducer from '../features/lang/langSlice'
import contrastReducer from "../features/contrast/contrastSlice";

export default configureStore({
    reducer: {
        lang: langReducer,
        contrast: contrastReducer
    },
})
