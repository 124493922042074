import { createSlice } from '@reduxjs/toolkit'

const initLang = JSON.parse(localStorage.getItem('lang') || JSON.stringify({ value: 'pl_PL', label: 'pl' }))

export const langSlice = createSlice({
    name: 'lang',
    initialState: {
        lang: initLang
    },
    reducers: {
        setLang: (state, action) => {
            state.lang = action.payload
            localStorage.setItem('lang', JSON.stringify(action.payload));
        },
    },
})

// Action creators are generated for each case reducer function
export const { setLang } = langSlice.actions

export default langSlice.reducer
