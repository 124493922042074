/* eslint-disable sort-imports */
import "@lsg/polyfills"; // NOTE!: This has to be the FIRST import!
import * as React from "react";
import { render } from "react-dom";
import { Provider } from 'react-redux'
import store from './store/store'
import App from "./App";
/*
import {Localization} from "@lsg/components";

// overrides the lsg default text with language specific values (needs to be set before mounting)
window.lsgTranslations = {
  texts: {
    "lsg.component._general.optional": "ideal",
    "lsg.component.InputTextfield.clear": "borrar la entrada",
  }
};
Localization.refresh();
 */

const root = document.getElementById("react-root");
render(<Provider store={store}><App /></Provider>, root);
