import {BrowserRouter, Route, Switch, Link} from "react-router-dom";
import * as React from "react";
import {connect} from "react-redux";
import CookieConsent from "react-cookie-consent";
import Header from "./components/Header";
import Footer from "./components/Footer";
// eslint-disable-next-line import/no-named-as-default
import Page from "./templates/Page/Page";
import HomePage from "./templates/HomePage/HomePage";
import AboutUsPage from "./templates/AboutUsPage/AboutUsPage";
import CareerPage from "./templates/CareerPage/CareerPage";
import TalentBankPage from "./templates/TalentBankPage/TalentBankPage";
import BigDataPage from "./templates/BigDataPage/BigDataPage";
import NewsPage from "./templates/NewsPage/NewsPage";
import ContactPage from "./templates/ContactPage/ContactPage";
import NewsListPage from "./templates/NewsListPage/NewsListPage";

import ScrollToTop from "./components/ScrollToTop";


class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contrastMode: 'default',
            pages: [],
            cookies: {
                pl: (
                    <>
                        <strong>Nasza strona wykorzystuje pliki cookie.</strong><br/>
                        Strona wykorzystuje niezbędne i obowiązkowe dla prawidłowego funkcjonowania strony internetowej oraz oferowanych w ramach strony funkcjonalności (takich jak nawigacja po stronie czy dostęp do bezpiecznych obszarów). Ta strona nie może działać prawidłowo bez tych plików cookie. Dodatkowo, strona wykorzystuje opcjonalne pliki cookie w celu statystycznym, dla personalizacji i poprawy funkcjonalności i jakości obsługi użytkowników. <br/><br/>
                        Klikając opcję  <strong>"Zaakceptuj wszystko"</strong>, użytkownik wyraża zgodę na umieszczenie na swoim urządzeniu zarówno niezbędnych plików cookie, jak i plików opcjonalnych. Klikając <strong>„Odrzuć opcjonalne”</strong>, użytkownik wyraża zgodę jedynie na cookie niezbędne do prawidłowego funkcjonowania strony. 
                    </>
                ),
                en: (
                    <>
                        <strong>This website uses cookies to store information on your computer.</strong><br/>
                        To provide you with an optimal website experience in particular, we use cookies and web technologies with your consent for functional, statistical, and convenience purposes, as well as for the display of personalized content.<br/><br/>
                        Some of these cookies are strictly necessary, while others help us to improve your experience by providing insights into how the site is being used.<br/><br/>
                        In detail, these are:<br/><br/>
                        
                        <strong>Strictly Necessary:</strong> Necessary cookies make our website operational by enabling core functions like page navigation and access to secure areas. This website cannot function properly without these cookies.<br/><br/>
                        If you are at least 16 years old, you can confirm that we may use these web technologies by clicking "Accept all". Otherwise, click "Reject optional".
                    </>
                )
            },
            cookiesButton: {
                pl: 'Zaakceptuj wszystko',
                en: 'Accept all'
            },
            cookiesDeclineButton: {
                pl: 'Odrzuć opcjonalne',
                en: 'Reject optional'
            },
            cookiesReadMore: {
                pl: 'Czytaj więcej',
                en: 'Read more'
            },
            cookiesMoreLink: {
                pl: "/pl/polityka-prywatnosci",
                en: "/en/cookies-policy"
            },
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lang.lang.value !== this.props.lang.lang.value) {
            this.fetchData(this.state.query, this.props.lang.lang.value, this.state.filters)
        }
    }

    fetchData = () => {
        console.log("fetchData",  this.props.lang.lang.value)
        const query = `query ReadPages($Locale: Locale, $Filter: PageFilterFields){
  readPages(locale: $Locale, filter: $Filter) {
    ...on PageInterface {
      id,
      menuTitle,
      urlSegment,
      link,
      absoluteLink,
      view
    }
  }
}`;
        const pageViews = {
            HomePage,
            AboutUsPage,
            CareerPage,
            TalentBankPage,
            BigDataPage,
            Page,
            NewsListPage,
            ContactPage
        };
        fetch(process.env.REACT_APP_APIURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                query,
                variables: {
                    "Locale": this.props.lang.lang.value,
                    "Filter": {
                        "className": {
                            "ne": "SilverStripe\\ErrorPage\\ErrorPage"
                        }
                    }
                },
            })
        })
            .then(r => r.json())
            .then((data) => {
                const pages = [];
                data.data.readPages.map((page) => {

                    pages.push({
                        name: `page-${page.id}`,
                        label: page.menuTitle,
                        path: page.link,
                        component: pageViews[page.view]
                    })
                });

                this.setState({...this.state, pages});
            })
            .catch((err) => {
                console.log(err);
            });
    };

    render() {
        return (
            <>
                <div className={`contrast-${  this.props.contrast.contrast}` + ' wrapper'}>
                <BrowserRouter>
                    <ScrollToTop/>
                    {/* ------ PAGE HEADER ------ */}
                    <Header/>
                    {/* ------ PAGE CONTENT ------ */}
                    {/* The section is the top level layout component within the LSG. It should be used to structure the main contents of your page [application] */}


                    <Switch>
                        <Route exact path="/">
                            <HomePage/>
                        </Route>
                        {this.state.pages.map((route, i) => (
                            <Route
                                exact
                                path={route.path}
                                component={route.component}
                                key={i}
                            />
                        ))}
                        <Route
                            path={"/news/*"}
                            component={NewsPage}
                            key="news"
                        />
                    </Switch>
                    <Footer/>
                    <CookieConsent
                        location="bottom"
                        buttonText={ this.state.cookiesButton[this.props.lang.lang.label] }
                        declineButtonText={ this.state.cookiesDeclineButton[this.props.lang.lang.label] }
                        cookieName="commerzbank_LP"
                        style={{ background: "#2B373B" }}
                        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                        declineButtonStyle={{ color: "#4e503b", fontSize: "13px" }}
                        buttonClasses="cookie-btn"
                        declineButtonClasses="cookie-btn"
                        enableDeclineButton={true}
                        flipButtons={true}
                        expires={360}
                    >
                            {/* <strong>This website uses cookies to store information on your computer.</strong><br/>
                            To provide you with an optimal website experience in particular, we use cookies and web technologies with your consent for functional, statistical, and convenience purposes, as well as for the display of personalized content.<br/><br/>
                            Some of these cookies are strictly necessary, while others help us to improve your experience by providing insights into how the site is being used.<br/><br/>
                            In detail, these are:<br/><br/>
                            <strong>Strictly Necessary:</strong> Necessary cookies make our website operational by enabling core functions like page navigation and access to secure areas. This website cannot function properly without these cookies.<br/><br/>
                            If you are at least 16 years old, you can confirm that we may use these web technologies by clicking "Accept All". Otherwise, click "Reject Optional". 
                            <Link to={ this.state.cookiesMoreLink[this.props.lang.lang.label] }>
                                { this.state.cookiesReadMore[this.props.lang.lang.label] }
                            </Link> */}
  
                        
                        { this.state.cookies[this.props.lang.lang.label] } 
                        <Link to={ this.state.cookiesMoreLink[this.props.lang.lang.label] }>
                            { this.state.cookiesReadMore[this.props.lang.lang.label] }
                        </Link>
                        
                    </CookieConsent>
                </BrowserRouter>
                </div>
            </>
        );
    }
}

// export default Layout;
function mapStateToProps(state) {
    return {
        lang: state.lang,
        contrast: state.contrast
    }
}

export default connect(mapStateToProps)(Layout)
