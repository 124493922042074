import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ScrollToTop() {
    const {pathname, hash} = useLocation();
    useEffect(() => {
        if (hash) {
            const section = document.getElementById(hash.substring(1));
            setTimeout(() => {
                if (section) {
                    section.scrollIntoView({behavior: 'smooth', block: 'center'});
                }
            }, 500)
        } else {
            window.scrollTo(0, 0);
        }

    }, [pathname]);

    return null;
}
