import {IconLink, PortalHeader, withReactRouterNavigation} from "@lsg/components";
import React from 'react';
import {location___globe} from "@lsg/icons";
import FontSizeChanger from 'react-font-size-changer';
import { compose } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import {withRouter} from "react-router-dom";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import {setLang} from "../features/lang/langSlice";
import Contrast from "./Contrast";
// import query from "./Header.graphql";

const RouterHeader = withReactRouterNavigation(PortalHeader);

class Header extends React.Component {
    constructor(props) {
        super(props);
console.log(props.lang.lang)
        this.state = {
            value: "",
            lang: props.lang,
            langLabel: props.lang.lang?.label==='pl'? "EN" : "PL",
            segmentValue: "",
            navigationItems: [],
            navigationTree: [],
            layerOpen: false,
            trackingSend: false,
            tagline: {
                pl: 'Centrum Technologii Cyfrowych w Polsce',
                en: 'Center of Digital Technologies in Poland'
            },
        };
    }

    componentDidMount() {
        this.fetchData();
        const options = {
            autoConfig: true,
            debug: false,
        };
        /*ReactPixel.init('481482140206748', null, options);
        ReactGA.initialize("UA-218768118-1");*/
    }

    componentDidUpdate(prevProps) {

        if(this.props.location.pathname !== prevProps.location.pathname){
           /* ReactGA.send({ hitType: "pageview", page: this.props.location.pathname });
            ReactPixel.pageView();*/
        }

       /* if(!this.state.trackingSend){
            ReactGA.send({ hitType: "pageview", page: this.props.location.pathname });
            ReactPixel.pageView();
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ trackingSend: true})
        }*/
        let activeLocation = this.props.location.pathname;
        if(this.props.location.hash){
            activeLocation += this.props.location.hash;
        }
        const activeNav = this.findActiveTreeItem(activeLocation);
        const isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;    
        if(activeNav !== this.state.value){
            if(prevProps.location.hash && this.props.location.pathname === (`${prevProps.location.pathname  }/`)){
            }else if(!isMobile){
                    // eslint-disable-next-line react/no-did-update-set-state
                    this.setState({ value: activeNav })
                    const navItem = document.getElementById(activeNav);
                    if(navItem){
//FIXME: dlaczego to nie tu jest?                        
                        // setTimeout(() => {navItem.click();}, 300);
                    }
                }
        }



        if (prevProps.lang.lang.value !== this.props.lang.lang.value) {
            this.fetchData();
            this.props.history.push('/');
        }
    }

    findActiveTreeItem = (path) => {
        let activeNav = null;

        this.state.navigationTree.map((navItem, i) => {
            if(navItem.to === path){
                activeNav = navItem.name;
            }

            if(navItem.children && navItem.children.length){
                navItem.children[0].children.map((subNavItem) => {
                        if(subNavItem.to === path){
                            activeNav = subNavItem.name;
                        }
                    }
                );
            }
        });

        return activeNav;
    }

    handleLangSwitch = () => {
        let newLang = null;
        if(this.state.langLabel === 'EN'){
            newLang = {
                label: 'en',
                value: 'en_US'
            }

            this.setState({
                langLabel: 'PL'
            });

        }else{
            newLang = {
                label: 'pl',
                value: 'pl_PL'
            }
            this.setState({
                langLabel: 'EN'
            });
        }
        this.props.setLang(newLang)
    }

    fetchData = () => {

        const query = `query ReadMenuItems($Locale: Locale, $Filter: MenuItemFilterFields) {
  readMenuItems(locale: $Locale, filter: $Filter) {
    menuTitle,
    link,
    subMenu {
      menuItems {
        nodes {
          id,
          menuTitle,
          link
        }
      }
    }
  }
}`;

        fetch(process.env.REACT_APP_APIURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                query,
                variables: {
                    "Locale" : this.props.lang.lang.value,
                    "Filter": {
                        "menuSetID": {
                            "eq": 1
                        }
                    }
                },
            })
        })
            .then(r => r.json())
            .then((data) => {
                const navigationTree = [];

                data.data.readMenuItems.map((navigationItem, i) => {
                    const subMenu = [];
                    const menuItem = {
                        name: `item-${i}`,
                        label: navigationItem.menuTitle,
                        id: `item-${i}`,
                        collapsible: false,
                        // href: navigationItem.link,
                        /* onMouseHoverChange: (event) => {
                            if(navigationItem.subMenu.menuItems.nodes.length && event) {
                                const el = document.getElementById(`item-${i}`);
                                // el.click();
                                // this.setState({ value: `item-${i}` })
                                // const eventChange = new Event('hover');
                                // el.dispatchEvent(eventChange);

                                // menuItem.active = true;
                            }
                        }, */
                        to: navigationItem.link,
                        collapsable: true,
                        onClick: (event) => {
                            /*event.preventDefault();
                            event.stopPropagation();*/
                        }
                    };
                    if(navigationItem.subMenu.menuItems.nodes.length) {
                        navigationItem.subMenu.menuItems.nodes.map((subItem, s) => {
                            subMenu.push({
                                label: subItem.menuTitle,
                                name: `item-${  i  }-${  s}`,
                                collapsable: true,
                                // onClick: event => alert('test 2'),
                                to: navigationItem.link.substring(0, navigationItem.link.length - 1) + subItem.link
                            })
                        });
                    }

                    /*if(subMenu.length){
                        // delete menuItem.to;
                        menuItem.children = [
                            {
                                label: '',
                                children: subMenu,
                                name: `sub-item-${  i}`
                            }
                        ];
                    }*/
                    navigationTree.push(menuItem);
                });

               this.setState({...this.state, navigationTree});
            })
            .catch((err) => {
                console.log(err);
            });
    };




    render() {
        return (
            <>
                <RouterHeader
                    navigationTree={this.state.navigationTree}
                    // navigationObject={this.state.navigationTree}
                    logoTo="/"
                    to="/"
                    name="header-main"
                    // logoAttrs={{ target: "_blank" }}
                    onLogoClick={(event) => {this.setState({ value: null });window.scrollTo(0, 0);}}
                    logoAlt="Commerzbank Logo"
                    /* logoSrcDesktop={"CB-2021-Logo_horizontal_RGB_negative.svg"} */
                    segmentLabel={this.state.tagline[this.props.lang.lang.label]}
                    isSticky
                    isFullUnderline
                    // onChange={value => this.setState({ value })}
                    onChange={(value,name,event) => {
                        // console.log('change');
                        const {hash, pathname} = window.location;
                        if(hash){
                            const section = document.getElementById(hash.substring(1));
                            if(section){
                                section.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            }
                        }

                        /* if (pathname === '/' && !hash){
                            this.setState({ value: null })
                        }else{
                            this.setState({ value })
                        } */
                        this.setState({ value })

                    }}

                    iconLinksMobileSidebar={
                        <>
                            <IconLink.Group direction={"horizontal"}>
                            <IconLink href="https://www.facebook.com/Commerzbank-Centrum-Technologii-Cyfrowych-w-Polsce-108871050714937" htmlAttrs={{ target: "_blank" }} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#dce6e6" width="22px" height="22px" viewBox="0 0 310 310" style={{enableBackground: "new 0 0 310 310" }} ><path d="M81.703 165.106h33.981V305a5 5 0 0 0 5 5H178.3a5 5 0 0 0 5-5V165.765h39.064a5 5 0 0 0 4.967-4.429l5.933-51.502a5 5 0 0 0-4.966-5.572h-44.996V71.978c0-9.732 5.24-14.667 15.576-14.667h29.42a5 5 0 0 0 5-5V5.037a5 5 0 0 0-5-5h-40.545A39.746 39.746 0 0 0 185.896 0c-7.035 0-31.488 1.381-50.804 19.151-21.402 19.692-18.427 43.27-17.716 47.358v37.752H81.703a5 5 0 0 0-5 5v50.844a5 5 0 0 0 5 5.001z"/></svg>
                            </IconLink>
                            <IconLink href="https://www.linkedin.com/company/commerzbank-branch-in-poland/" htmlAttrs={{ target: "_blank" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#dce6e6" width="22px" height="22px" viewBox="0 0 310 310" style={{enableBackground: "new 0 0 310 310" }} ><path d="M72.16 99.73H9.927a5 5 0 0 0-5 5v199.928a5 5 0 0 0 5 5H72.16a5 5 0 0 0 5-5V104.73a5 5 0 0 0-5-5zM41.066.341C18.422.341 0 18.743 0 41.362 0 63.991 18.422 82.4 41.066 82.4c22.626 0 41.033-18.41 41.033-41.038C82.1 18.743 63.692.341 41.066.341zM230.454 94.761c-24.995 0-43.472 10.745-54.679 22.954V104.73a5 5 0 0 0-5-5h-59.599a5 5 0 0 0-5 5v199.928a5 5 0 0 0 5 5h62.097a5 5 0 0 0 5-5V205.74c0-33.333 9.054-46.319 32.29-46.319 25.306 0 27.317 20.818 27.317 48.034v97.204a5 5 0 0 0 5 5H305a5 5 0 0 0 5-5V194.995c0-49.565-9.451-100.234-79.546-100.234z"/></svg>
                            </IconLink>
                            <IconLink icon={location___globe} onClick={() => this.handleLangSwitch()}>
                                {this.state.langLabel}
                            </IconLink>
                                <Contrast/>
                                <FontSizeChanger
                                    targets={['.wrapper']}
                                    onChange={(element, newValue, oldValue) => {
                                        // console.log(element, newValue, oldValue);
                                    }}
                                    options={{
                                        stepSize: 2,
                                        range: 3
                                    }}
                                    customButtons={{
                                        style: {
                                            width: '20px',
                                            height: '20px',
                                            lineHeight: '20px',
                                            cursor: 'pointer'
                                        },
                                    }}
                                />
                            </IconLink.Group>
                        </>
                    }

                    iconLinksInteraction={
                        <>
                            <IconLink href="https://www.facebook.com/Commerzbank-Centrum-Technologii-Cyfrowych-w-Polsce-108871050714937" htmlAttrs={{ target: "_blank" }} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#dce6e6" width="22px" height="22px" viewBox="0 0 310 310" style={{enableBackground: "new 0 0 310 310" }} ><path d="M81.703 165.106h33.981V305a5 5 0 0 0 5 5H178.3a5 5 0 0 0 5-5V165.765h39.064a5 5 0 0 0 4.967-4.429l5.933-51.502a5 5 0 0 0-4.966-5.572h-44.996V71.978c0-9.732 5.24-14.667 15.576-14.667h29.42a5 5 0 0 0 5-5V5.037a5 5 0 0 0-5-5h-40.545A39.746 39.746 0 0 0 185.896 0c-7.035 0-31.488 1.381-50.804 19.151-21.402 19.692-18.427 43.27-17.716 47.358v37.752H81.703a5 5 0 0 0-5 5v50.844a5 5 0 0 0 5 5.001z"/></svg>
                            </IconLink>
                            <IconLink href="https://www.linkedin.com/company/commerzbank-branch-in-poland/" htmlAttrs={{ target: "_blank" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#dce6e6" width="22px" height="22px" viewBox="0 0 310 310" style={{enableBackground: "new 0 0 310 310" }} ><path d="M72.16 99.73H9.927a5 5 0 0 0-5 5v199.928a5 5 0 0 0 5 5H72.16a5 5 0 0 0 5-5V104.73a5 5 0 0 0-5-5zM41.066.341C18.422.341 0 18.743 0 41.362 0 63.991 18.422 82.4 41.066 82.4c22.626 0 41.033-18.41 41.033-41.038C82.1 18.743 63.692.341 41.066.341zM230.454 94.761c-24.995 0-43.472 10.745-54.679 22.954V104.73a5 5 0 0 0-5-5h-59.599a5 5 0 0 0-5 5v199.928a5 5 0 0 0 5 5h62.097a5 5 0 0 0 5-5V205.74c0-33.333 9.054-46.319 32.29-46.319 25.306 0 27.317 20.818 27.317 48.034v97.204a5 5 0 0 0 5 5H305a5 5 0 0 0 5-5V194.995c0-49.565-9.451-100.234-79.546-100.234z"/></svg>
                            </IconLink>
                            <IconLink icon={location___globe} onClick={() => this.handleLangSwitch()}>
                                {this.state.langLabel}
                            </IconLink>
                        </>
                    }
                    /* iconLinksQuickLink={
                        <Button id="cta-btn" href="https://commerzbank-poland.breezy.hr/" htmlAttrs={{ target: "_blank" }}>
                            Aplikuj
                        </Button>
                    } */
                    iconLinksQuickLink={
                        <>
                        <Contrast/>
                        <FontSizeChanger
                            targets={['.wrapper']}
                            onChange={(element, newValue, oldValue) => {
                                // console.log(element, newValue, oldValue);
                            }}
                            options={{
                                stepSize: 2,
                                range: 3
                            }}
                            customButtons={{
                                style: {
                                    width: '20px',
                                    height: '20px',
                                    lineHeight: '20px',
                                    cursor: 'pointer'
                                },
                            }}
                        />
                        </>
                    }
                    value={this.state.value}
                />
                {/* <ul>
                {this.state.navigationTree.map((navigationItem) => {
                    return <li><Link to={navigationItem.to}>{navigationItem.label}</Link></li>;
                })}
                </ul> */}

            </>
        );
    }
}

// export default Header;
function mapStateToProps(state) {
    return { lang: state.lang }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setLang: (action) => dispatch(setLang(action))
    }
};
//export default connect(mapStateToProps, mapDispatchToProps)(Header)

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Header)
