import { createSlice } from '@reduxjs/toolkit'

export const contrastSlice = createSlice({
    name: 'contrast',
    initialState: {
        contrast: 'initial'
    },
    reducers: {
        setContrast: (state, action) => {
            state.contrast = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setContrast } = contrastSlice.actions

export default contrastSlice.reducer
