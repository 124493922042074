import React from "react"
import {ActionGroup, Button, Stage} from "@lsg/components";

class Hero extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <>
                <Stage
                    theme={Stage.Themes.DARK}
                    //overline="Kostenloses Girokonto"
                    headline="Nie aplikuj..."
                    leadText="... chyba że chcesz mieć realny wpływ na cyfrową transformację banku"
                    button={<ActionGroup centeredLayout>
                        <Button>Aplikuj</Button>
                        <Button look={Button.Looks.SECONDARY}>Dowiedz się więcej</Button>
                    </ActionGroup>}
                    scrollerText="Czytaj dalej"
                    backgroundImageImgSrc={this.props.backgroundImage}
                />
            </>
            );

    }
}

export default Hero;
